import classNames from 'classnames'
import { Button } from '#app/components/ui/button.tsx'
import React, { useState } from 'react'

export type NotificationProps = {
	issue: string | null
	onClickSuccess?: () => void
	onClickDecline?: () => void
}

const valid_issues = ['unauthorized', 'sin_required', 'ssn_required', 'T1013_required']

/**
 * Suggested colors for different issues
 *
 * error: bg-red-600
 * warning: bg-amber-600
 * success: bg-green-600
 *
 * @param issue
 * @param onClickSuccess
 * @param onClickDecline
 * @constructor
 */
const Notification = ({ issue = null, onClickSuccess, onClickDecline }: NotificationProps) => {
	const [show, setShow] = useState(issue)

	if (!valid_issues.includes(issue || '')) {
		return null
	}

	let color = ''
	let textColor = 'text-white'
	let title = ''
	let description = ''

	switch (issue) {
		case 'unauthorized':
			color = 'bg-amber-400'
			textColor = 'text-yellow-900'
			title = 'Authorization Required'
			description = 'Your session has most likely expired.<br/>Please log in again.'
			break
		case 'sin_required':
			color = 'bg-amber-600'
			title = 'Social Insurance Number Requested'
			description =
				'Your tax consultant has requested your Social Insurance Number. Once submitted, your encrypted number will be accessible exclusively by your tax consultant.'
			break
		case 'ssn_required':
			color = 'bg-amber-600'
			title = 'Social Security Number Requested'
			description =
				'Your tax consultant has requested your Social Security Number. Once submitted, your encrypted number will be accessible exclusively by your tax consultant.'
			break
		case 'T1013_required':
			color = 'bg-amber-600'
			title = 'T1013 Requested'
			description =
				'Your tax consultant has requested you sign a T1013. The T103 form lets your tax consultant access your CRA account to manage your tax matters.'
			break
	}

	function handleClickSuccess() {
		setShow(null)
		// @ts-ignore
		onClickSuccess()
	}

	function handleClickDecline() {
		setShow(null)
		// @ts-ignore
		onClickDecline()
	}

	return (
		<div>
			{show !== null && (
				<div className={classNames(color, textColor, 'my-4 flex items-center rounded-lg p-4')}>
					<div className="flex w-full items-center justify-between font-bold">
						<div className="mr-3">
							<div className="mb-2 flex">{title}</div>
							<div className="flex text-sm" dangerouslySetInnerHTML={{ __html: description as string }}></div>

							{'sin_required' === issue && (
								<div className="mt-4 flex gap-3">
									<div>
										<Button size="sm" className="bg-blue-600 px-2 font-bold text-white" onClick={handleClickSuccess}>
											SUBMIT
										</Button>
									</div>
									<div>
										<Button size="sm" className="bg-blue-600 px-2 font-bold text-white" onClick={handleClickDecline}>
											DECLINE
										</Button>
									</div>
								</div>
							)}
							{'ssn_required' === issue && (
								<div className="mt-4 flex gap-3">
									<div>
										<Button size="sm" className="bg-blue-600 px-2 font-bold text-white" onClick={handleClickSuccess}>
											SUBMIT
										</Button>
									</div>
									<div>
										<Button size="sm" className="bg-blue-600 px-2 font-bold text-white" onClick={handleClickDecline}>
											DECLINE
										</Button>
									</div>
								</div>
							)}
							{'T1013_required' === issue && (
								<div className="mt-4 flex gap-3">
									<div>
										<Button size="sm" className="bg-blue-600 px-2 font-bold text-white" onClick={handleClickSuccess}>
											SIGN
										</Button>
									</div>
									<div>
										<Button size="sm" className="bg-blue-600 px-2 font-bold text-white" onClick={handleClickDecline}>
											DECLINE
										</Button>
									</div>
								</div>
							)}
						</div>
						<div>
							<Button size="sm" className="bg-blue-600 px-2 font-bold text-white" onClick={handleClickSuccess}>
								{'X'}
							</Button>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default Notification
